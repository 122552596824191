@import url('https://fonts.googleapis.com/css?family=Roboto|Montserrat|Lato|Open+Sans&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .MuiInputBase-root,
  .MuiTypography-root {
    color: var(--color-body-invert);
  }
  .MuiInputLabel-root {
    color: var(--color-body-invert-dim);
  }
}

.body {
  font-family: 'Lato', sans-serif;
  background-color: var(--color-body);
  color: var(--color-body-invert);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// 34ba9b
// f68c70

:root {
  --color-pick: #4b5db3;
  // --color-pick: #7A93DC;
  --height-nav: 50px;

  // --color-themecolor-1: linear-gradient(to right top, #ff6f59, #e1853a, #bc9631, #95a242, #70a860, #52a773, #32a587, #0ba198, #0099a0, #0091a5, #0088a8, #007ea7);;
  --color-themecolor-1: #c4005b;
  --color-themecolor-2: #803bc2;
  --color-themecolor-3: #fa1e4e;
  --color-themecolor-4: #29be3b;
  --color-themecolor-5: #ffe20f;
  --color-themecolor-6: #4d4dff;

  .themecolor1 {
    --color-dark-primary: #ef006f;
    --color-dark-secondary: #c4005b;
    --color-dark-tertiary: #8f0043;
    --color-dark-tertiary-trans: rgba(143, 0, 67, 0.2);
    --color-light-primary: #c4005b;
    --color-light-secondary: #ce2e78;
    --color-light-tertiary: #d95c96;
    --color-light-tertiary-trans: rgba(217, 92, 150, 0.2);
  }

  .themecolor2 {
    --color-dark-primary: #a557ee;
    --color-dark-secondary: #803bc2;
    --color-dark-tertiary: #5e2b8e;
    --color-dark-tertiary-trans: rgba(94, 43, 142, 0.2);
    --color-light-primary: #803bc2;
    --color-light-secondary: #975ecd;
    --color-light-tertiary: #ae82d8;
    --color-light-tertiary-trans: rgba(174, 130, 216, 0.2);
  }

  .themecolor3 {
    --color-dark-primary: #fa1e4e;
    --color-dark-secondary: #cd1940;
    --color-dark-tertiary: #a01432;
    --color-dark-tertiary-trans: rgba(160, 20, 50, 0.2);
    --color-light-primary: #cd1940;
    --color-light-secondary: #d62c51;
    --color-light-tertiary: #dd5270;
    --color-light-tertiary-trans: rgba(221, 82, 112, 0.2);
  }

  .themecolor4 {
    --color-dark-primary: #32e847;
    --color-dark-secondary: #29be3b;
    --color-dark-tertiary: #1b7926;
    --color-dark-tertiary-trans: rgba(27, 121, 38, 0.2);
    --color-light-primary: #29be3b;
    --color-light-secondary: #4fc95e;
    --color-light-tertiary: #8ff29a;
    --color-light-tertiary-trans: rgba(143, 242, 154, 0.2);
  }

  .themecolor5 {
    --color-dark-primary: #ffe20f;
    --color-dark-secondary: #dbc208;
    --color-dark-tertiary: #b49f07;
    --color-dark-tertiary-trans: rgba(180, 159, 7, 0.2);
    --color-light-primary: #ceaf00;
    --color-light-secondary: #e5ca02;
    --color-light-tertiary: #ffec5e;
    --color-light-tertiary-trans: rgba(255, 236, 94, 0.2);
  }

  .themecolor6 {
    --color-dark-primary: #6d6dff;
    --color-dark-secondary: #4d4dff;
    --color-dark-tertiary: #2b2bb4;
    --color-dark-tertiary-trans: rgba(43, 43, 180, 0.2);
    --color-light-primary: #4d4dff;
    --color-light-secondary: #6d6dff;
    --color-light-tertiary: #8d8dff;
    --color-light-tertiary-trans: rgba(141, 141, 255, 0.2);
  }

  .theme_dark {
    --color-body: #222831;
    --color-body-dim: #363b43;
    --color-body-invert: #fcfcfc;
    --color-body-invert-dim: #a1a1a1;
    --color-primary: var(--color-dark-primary);
    --color-secondary: var(--color-dark-secondary);
    --color-tertiary: var(--color-dark-tertiary);
    --color-tertiary-trans: var(--color-dark-tertiary-trans);
    --color-default: rgba(217, 217, 217, 1);
    --color-selected: rgba(217, 217, 217, 1);
    --color-hover: rgba(217, 217, 217, 0.2);
    --color-white: white;
    --color-black: black;
    --color-success: #437a34;
    --color-warning: #49a078;
    --color-failure: #8c030e;

    --color-sidebar-header: #444444;
    --color-sidebar-header-active: var(--color-tertiary-trans);
    --color-sidebar-element: #2d2d2d;
    --color-sidebar-fg-hover: rgba(255, 255, 255, 1);
    --color-sidebar-fg: rgba(255, 255, 255, 0.7);

    --color-nav: #2a3036;
    --color-nav-shadow: rgba(252, 252, 252, 0.15);
    --color-nav-text: var(--color-white);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary);
    --color-nav-slider: var(--color-body);
    --color-navitem-mobile: var(--color-white);

    --color-btn-primary-bg: var(--color-primary);
    --color-btn-primary-fg: var(--color-body-invert);
    --color-btn-primary-fg-hover: black;
    --color-btn-secondary-bg: var(--color-secondary);
    --color-btn-secondary-fg: var(--color-body-invert);
    --color-btn-secondary-fg-hover: black;
    --color-btn-tertiary-bg: var(--color-tertiary);
    --color-btn-tertiary-fg: var(--color-body-invert);
    --color-btn-tertiary-fg-hover: black;
    --color-btn-default-bg: var(--color-default);
    --color-btn-default-fg: var(--color-body-invert);
    --color-btn-default-fg-hover: black;

    --color-btn-action-danger-bg: #dc758f;

    --color-dialog: var(--color-nav);

    --color-tag: #404040;
  }

  .theme_light {
    --color-body: #fcfcfc;
    --color-body-dim: #e6e6e6;
    --color-body-invert: #252525;
    --color-body-invert-dim: #6e6e6e;
    --color-primary: var(--color-light-primary);
    --color-secondary: var(--color-light-secondary);
    --color-tertiary: var(--color-light-tertiary);
    --color-tertiary-trans: var(--color-light-tertiary-trans);
    --color-default: rgba(64, 64, 64, 1);
    --color-selected: rgba(64, 64, 64, 1);
    --color-hover: rgba(64, 64, 64, 0.2);
    --color-white: white;
    --color-black: black;
    --color-success: #437a34;
    --color-warning: #49a078;
    --color-failure: #8c030e;

    --color-sidebar-header: #e6e6e6;
    --color-sidebar-header-active: var(--color-tertiary-trans);
    --color-sidebar-element: var(--color-body);
    --color-sidebar-fg-hover: rgba(0, 0, 0, 1);
    --color-sidebar-fg: rgba(0, 0, 0, 0.7);

    --color-nav: #ececec;
    --color-nav-shadow: rgba(0, 0, 0, 0.15);
    --color-nav-text: var(--color-black);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary);
    --color-nav-slider: var(--color-body);
    --color-navitem-mobile: var(--color-black);

    --color-btn-primary-bg: var(--color-primary);
    --color-btn-primary-fg: var(--color-body-invert);
    --color-btn-primary-fg-hover: white;
    --color-btn-secondary-bg: var(--color-secondary);
    --color-btn-secondary-fg: var(--color-body-invert);
    --color-btn-secondary-fg-hover: white;
    --color-btn-tertiary-bg: var(--color-tertiary);
    --color-btn-tertiary-fg: var(--color-body-invert);
    --color-btn-tertiary-fg-hover: white;
    --color-btn-default-bg: var(--color-default);
    --color-btn-default-fg: var(--color-body-invert);
    --color-btn-default-fg-hover: white;

    --color-btn-action-danger-bg: #b33951;

    --color-dialog: var(--color-body);

    --color-tag: #e6e6e6;
  }
}

// /* Color Theme Swatches in Hex */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-hex { color: #0F2F59; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-hex { color: #1F4C73; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-hex { color: #3A6D8C; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-hex { color: #75AABF; }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-hex { color: #9CD3D9; }

// /* Color Theme Swatches in RGBA */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-rgba { color: rgba(15, 47, 89, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-rgba { color: rgba(30, 75, 114, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-rgba { color: rgba(57, 108, 140, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-rgba { color: rgba(116, 170, 191, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-rgba { color: rgba(156, 210, 216, 1); }

// /* Color Theme Swatches in HSLA */
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-1-hsla { color: hsla(213, 70, 20, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-2-hsla { color: hsla(208, 57, 28, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-3-hsla { color: hsla(202, 41, 38, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-4-hsla { color: hsla(196, 36, 60, 1); }
// .Vintage-blue-background.-Rough-painted-wall-of-sapphire-color.-Imperfect-plane-of-blue-colored.-Uneven-old-decorative-toned-backdrop-of-cyan-tint.-Texture-of-sapphirine-hue.-Ornamental-stony-surface.-5-hsla { color: hsla(185, 44, 73, 1); }
